<template>
  <b-form-group
    :label="label"
  >
    <div
      class="d-flex flex-nowrap"
    >
      <div
        class="emails-input flex-grow-1"
      >
        <span
          class="email-chip"
          v-for="(email, index) in selectedEmails"
          :key="email"
        >
          {{email}}
          <a href="#" class="remove" @click="removeMail(index)">
            <font-awesome-icon icon="remove" />
          </a>
        </span>
        <b-form-input
          :id="fieldId"
          type="email"
          :placeholder="placeholder"
          v-model="currentValue"
          @blur="onBlurEvent"
          v-on:keyup.enter="addMail"
        ></b-form-input>
      </div>
      <b-button
        variant="success"
        @click="addMail"
        :disabled="!fieldValidity"
        size="xs"
      >
        <font-awesome-icon icon="add" class="fa-fw" />
      </b-button>
    </div>
  </b-form-group>
</template>

<script>

export default {
  name: 'FieldMultiMail',
  model: {
    prop: 'selectedEmails',
    event: 'input',
  },
  props: {
    fieldId: String,
    placeholder: String,
    title: String,
    textValue: String,
    state: Boolean,
    errorMessage: String,
    readOnly: Boolean,
    onBlur: Function,
    label: String,
    defaultEmails: Array,
  },
  data() {
    return {
      currentValue: null,
      selectedEmails: this.defaultEmails || [],
    };
  },
  computed: {
    failed() {
      return this.errorMessage || 'Il campo è obbligatorio';
    },
    fieldValidity() {
      // eslint-disable-next-line max-len
      const res = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return res.test(String(this.currentValue).toLowerCase());
    },
  },
  methods: {
    onBlurEvent(e) {
      // console.log('text - onblur');
      if (!this.onBlur) return;

      this.onBlur(e);
    },
    addMail() {
      // console.log('email', this.currentValue);
      if (this.fieldValidity) {
        this.selectedEmails.push(this.currentValue);
        this.currentValue = '';
        this.$emit('input', this.selectedEmails);
      } else {
        console.log('not an email');
      }
    },
    removeMail(idx) {
      this.selectedEmails.splice(idx, 1);
      this.$emit('input', this.selectedEmails);
    },
  },
};
</script>
